import __NS_DYNAMIC_REACT_CONTENT___0 from "./biometrics-security-path-content.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
export const BiometricsSecurityPathDashboard = ({
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  return dashboardMfeEnabled ? createElement(Fragment, null, loginPreferencesMfeEnabled ? createElement(Content, {
    hash: "6c682b24edbafbd11e0ec940c1217a16",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "7bb4357f15ecb0f1ae1c0260ee1218f8",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })) : createElement(Content, {
    hash: "73927cb3bb6dd3e90bfeb5bcc8f44950",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};