function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-failure.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement } from 'react';
import { Body, Title } from 'legos/typography';
import { ContentBlock } from 'legos/content-block';
import { ButtonRaised } from 'legos/button/raised';
import { EnrollmentContainer, HeadingContainer, ChangeOrDisable } from './styles/acp-biometrics-intoduction-success-failure.ccm.css';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { CenterChildren } from '../components';
import { Illustration } from 'legos/illustration';
import { BiometricsSecurityPathDashboard } from './biometrics-security-path-content';
export const BiometricsFailure = ({
  title,
  cancelAnchor,
  biometricsType,
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  const titleContent = biometricsType === 'touch' ? createElement(Content, {
    hash: "9fc402ec20def161a9f8d1c5a6e596b3",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "39bdc6b6e7c094158eda5a641874d00d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
  return createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(EnrollmentContainer.div, null, createElement(Illustration, {
    type: "general-error",
    label: "image illustrating error"
  }), createElement(HeadingContainer.div, null, createElement(Title, null, titleContent)), createElement(ChangeOrDisable.div, null, createElement(Body, null, createElement(Content, {
    hash: "afbd702d71ac5dee09a2a00f086eff3c",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Fingerprint' : 'Face ID'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(BiometricsSecurityPathDashboard, {
    dashboardMfeEnabled: dashboardMfeEnabled,
    loginPreferencesMfeEnabled: loginPreferencesMfeEnabled
  }))), createElement(CenterChildren, null, createElement(ButtonRaised, _extends({
    color: "positive"
  }, cancelAnchor), createElement(Content, {
    hash: "929066d82bda042abb81ef969873dbfa",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))))));
};