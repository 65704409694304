export default { 
  h3f0c772c9241fa193bf4186cae2d3e77: [[({
  brand: brand
}, []) => ["Your ", brand.name, " Account is a Fingerprint Away"], {}]],
  h75409b1105f4e77fca7a956cdd6a4a16: [[({
  brand: brand
}, []) => ["You now have the option of using Face ID\xAE to log in to the", " ", brand.name, " mobile app! Once you enable this option, login will be much easier and faster!"], {}]],
  h7a88760c469505e15902e3ceacf81edf: [[({
  brand: brand
}, []) => ["You are about to enable Fingerprint Login for quick access to the ", brand.name, " mobile app as an alternative to your username and password."], {}]],
  h8eacb206b6af069ff1fb5b5f8256e031: [[({}, [C0]) => [C0()], {}]],
  h93e4b026d01e084747ff31bb9541d459: [[({}, []) => ["Face ID is a registered trademark of Apple. Inc."], {}]],
  h9fdbf673aaba83b1edfe55e54546e08c: [[({}, []) => ["REMEMBER: Anyone with a stored fingerprint on this device will have access to your Online Account."], {}]],
  hd27c06177b05282fa7579ae9e7b1cc39: [[({
  brand: brand
}, []) => ["Enable Facial Recognition Login for Your ", brand.name, " Account."], {}]],
  hd2f6d7cec280ad0577305626930e9466: [[({}, []) => ["Cancel"], {}]],
  he858b4b3e4f85ed011409c54e3b8acc2: [[({}, [C0, C1]) => ["Simply check the Enable", " ", C0(), " Login checkbox on the Login page and follow the instructions. If you haven\u2019t already, you will also need to enable", " ", C1(), " ", "in your device\u2019s settings."], {}]] 
}