import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-introduction.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { Body, Title, Disclosure } from 'legos/typography';
import { createElement, Fragment } from 'react';
import { EnrollmentContainer } from './styles/acp-biometrics-intoduction-success-failure.ccm.css';
import { ButtonRaised } from 'legos/button/raised';
import { ButtonFlat } from 'legos/button/flat';
import { ContentBlock } from 'legos/content-block';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { CenterChildren } from '../components';
import { SystemIcon } from 'legos/system-icon';
import { Illustration } from 'legos/illustration';
import { isAndroid, isIOS } from 'packages/platform-detector';
import { SendEventAnalytics } from 'apps/acp/packages/react-analytics';
import { BIOMETRICS_ANALYTICS_CONSTANTS } from '../acp-biometrics-ga-analytics';
import { useSendAnalytics } from 'packages/react-analytics';
import { useHistory } from 'packages/react-nano-router';
export const BiometricsIntroduction = ({
  title,
  biometricsType,
  brand,
  backLinks,
  nextGoLink
}) => {
  const sendAnalytics = useSendAnalytics();
  const history = useHistory();
  const moveBack = {
    /**
     * This will help in capture send analytics event
     * when clicked on InPageFlowLayout back button.
     */
    href: '#',
    onClick: e => {
      e.preventDefault();
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.WARNING.DISMISS,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  };
  return createElement(Fragment, null, createElement(SendEventAnalytics, {
    analyticsEvent: {
      event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.WARNING.VIEWED,
      payload: {
        category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
        label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
      }
    }
  }), createElement(InPageFlowLayout, {
    title: title,
    backAnchor: moveBack
  }, createElement(ContentBlock, null, createElement(EnrollmentContainer.div, null, biometricsType === 'touch' && createElement(IntroIconFingerPrint, {
    type: biometricsType
  }), biometricsType === 'face' && createElement(IntroIconFace, null), createElement(Title, null, biometricsType === 'touch' && createElement(Content, {
    hash: "3f0c772c9241fa193bf4186cae2d3e77",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), biometricsType === 'face' && createElement(Content, {
    hash: "d27c06177b05282fa7579ae9e7b1cc39",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, biometricsType === 'touch' && createElement(Content, {
    hash: "7a88760c469505e15902e3ceacf81edf",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), biometricsType === 'face' && createElement(Content, {
    hash: "75409b1105f4e77fca7a956cdd6a4a16",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "e858b4b3e4f85ed011409c54e3b8acc2",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Fingerprint' : 'Face ID', () => biometricsType === 'touch' ? 'fingerprint access' : 'Face ID'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), biometricsType === 'touch' && createElement(Disclosure, null, createElement(Content, {
    hash: "9fdbf673aaba83b1edfe55e54546e08c",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(CenterChildren, null, createElement(ButtonRaised, {
    color: "positive",
    onClick: () => {
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.BIOMETRICS_INTRODUCTION.WARNING_GET_STARTED,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', nextGoLink.url());
    }
  }, createElement(Content, {
    hash: "8eacb206b6af069ff1fb5b5f8256e031",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Get Started' : 'Close'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), biometricsType === 'touch' && createElement(ButtonFlat, {
    color: "default",
    onClick: () => {
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.BIOMETRICS_INTRODUCTION.WARNING_CANCEL,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  }, createElement(Content, {
    hash: "d2f6d7cec280ad0577305626930e9466",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), biometricsType === 'face' && createElement(Disclosure, null, createElement(Content, {
    hash: "93e4b026d01e084747ff31bb9541d459",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))))));
};

const IntroIconFingerPrint = ({
  type
}) => {
  return createElement(Fragment, null, type === 'touch' && isAndroid() && createElement(SystemIcon, {
    type: "fingerprintAndroid",
    color: "primary",
    size: 9
  }), type === 'touch' && isIOS() && createElement(SystemIcon, {
    type: "fingerprint",
    color: "primary",
    size: 9
  }));
};

const IntroIconFace = () => createElement(Illustration, {
  type: "face-id",
  label: "image illustrating face id"
});