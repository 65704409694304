import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-esign.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { ContentBlock } from 'legos/content-block';
import { Body, Bold, LinkBody, Title } from 'legos/typography';
import { EsignLayout, MrgTop, CommunicationLayout, LiLayout } from './styles/acp-biometrics-esign.ccm.css';
import { useExternalLinkHandler } from 'packages/external-link-opener/react';
import { useAcpBiometricsTheme } from '../app.theme';
export const BiometricsEsign = ({
  title,
  cancelAnchor,
  children
}) => {
  return createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(Title, null, "E-Sign Consent"), createElement(CommunicationLayout.div, null, createElement(Body, null, "Communications will be sent to the following e-mail address we have on file for you. You can change your email address below."), children)));
};
export const EsignDisclosureContent = ({
  cardType,
  esignProductName,
  brand
}) => {
  const {
    lightDisclosureGreyColor,
    neutralBlackColor
  } = useAcpBiometricsTheme();
  return createElement(EsignLayout.div, {
    $borderColor: lightDisclosureGreyColor
  }, createElement(MrgTop.div, null, createElement(Body, null, createElement(Content, {
    hash: "b0b24722af6b7a54df287e4acf6e8ef5",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(Body, null, createElement(Content, {
    hash: "b96fc4642b6c895c2954b3def5eda61e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "6d7d7542eaa9f7f630ea9d6c1fff66c4",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "4057a70789b26a51d7d63b043c7b0040",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "89928af988fc9e26898040de7c0bce5f",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "b8da82ee441410ed29e2b4f3be17707a",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    })],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "81d2d621aa697d8afadc03764eea9702",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "f34fdd034b956590bfca03837898e1a8",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "d1b18eb6bf598d34f16b5ffced48bd4b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "71d18069479f4a0e6a1739a95fdfa8f4",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "432cf9bd29ab37d0057f954eb9af3c40",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "168ddb58ff7ef30d0398d786486cad6a",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "2d16d340778eb1eeee703bb050c449e0",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "221bc9ae24f6fef9eafb4e91db69c960",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "8e9e0918f03347ae3d857371f97a7b2c",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "24279478fca7b7d6e0fcc3b460b5643b",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "e3302bfd85ca1f1936e8e22ba340bf61",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "0889a258c7de0956dea505e6906eac3e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "5f0cca1d5bf762aecee3a566635b65b0",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "71951053429b5ea4f139421b68f4b67c",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "013d096badfa5ff7a5d1c5c3de3ce75b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "dcea95e82d5f40eff0596e4191e8933c",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(Bold, null, createElement(LinkBody, useExternalLinkHandler(`tel:${brand.phone}`), brand.phone_formatted)), ' ', createElement(Content, {
    hash: "0fe90c9105e7981445139998780aa61d",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "5c4de99a4a60b259fa74cb6c8fc7462c",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "b7397a5f0a542fb65c81c9af8d165c25",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "76fadd26274c0358199d987fa74e4323",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })));
};